import { navigate } from 'gatsby'
import React, { useEffect } from "react"
import { CurrentUserState } from '../state/CurrentUserState'

const LogoutPageWrapper = () => {
  const { logout } = CurrentUserState.useContainer()
  useEffect(() => {
    if (!window || typeof window === 'undefined') return null
    logout().then(() => navigate(`/login${window.location.search}`))
    // eslint-disable-next-line
  }, [])
  return null
}

const LogoutPage = props => {
  if (typeof window === "undefined") return null
  return <LogoutPageWrapper {...props} />
}

export default LogoutPage

